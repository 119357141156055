.container-fluid.widget.activity-time-selector {
  padding: 0px;
}
.container-fluid.widget.activity-time-selector .row-fluid [class*="span"] {
  min-height: 0;
}
html.rtl .activity-time-selector .radio-label span.time {
  padding-right: 20px;
}
.activity-time-selector .message {
  margin-top: 10px;
}
.activity-time-selector .checkbox,
.activity-time-selector .radio {
  margin-top: 0px;
  margin-bottom: 0px;
}
.activity-time-selector ul.nav {
  margin-bottom: 5px;
}
.activity-time-selector .no-pickup-msg {
  font-size: 0.8em;
  margin: 10px;
  margin-bottom: 15px;
  margin-top: 15px;
}
.activity-time-selector .pickup-price-container,
.activity-time-selector .dropoff-price-container {
  font-size: 0.8em;
  margin-top: 8px;
}
.activity-time-selector h4 {
  font-size: 16px;
  margin-bottom: 4px;
}
.activity-time-selector .roundbox {
  padding: 15px;
  border: 1px solid #DDD;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  font-size: 16px;
  vertical-align:middle;
}
.activity-time-selector .start-times table {
  margin-bottom: 0px;
}
.activity-time-selector .start-times table th {
  border-bottom-style: none;
}
.activity-time-selector .start-times table td span.label {
  margin-left: 8px;
}
.activity-time-selector .start-times table label {
  font-size: 14px;
}
.activity-time-selector .no-start-times-container {
  margin-top: 10px;
}
.activity-time-selector table.no-border {
  border-style: none;
}
.activity-time-selector table.no-border tr {
  border-style: none;
}
.activity-time-selector table.no-border tr td {
  border-style: none;
}
.activity-time-selector .activity-selector {
  margin-bottom: 20px;
}
.activity-time-selector .included-msg {
  color: #777;
}
.activity-time-selector .separate-msg {
  color: #777;
}
.activity-time-selector .booking-pax-restriction {
  font-size: 0.9em;
  margin-top: 5px;
  display: inline-block;
  margin-right: 10px;
}
.activity-time-selector .opening-hours .table {
  margin-bottom: 0px;
}
.activity-time-selector .opening-hours td {
  font-size: 0.9em;
}
.activity-time-selector .opening-hours h4 {
  font-weight: bold;
}
.activity-time-selector .passenger-selector-container .participant-selector {
  min-width: 90px;
  padding-right: 15px;
}
.activity-time-selector .passenger-selector-container .participant-selector label {
  font-size: 13px;
  margin-bottom: 1px;
}
.activity-time-selector .passenger-selector-container .participant-selector select {
  margin-bottom: 0px;
}
.activity-time-selector .passenger-selector-container .price-desc {
  font-size: 13px;
  clear: both;
  display: block;
  padding-top: 10px;
}
.activity-time-selector .passenger-selector-container .pickup-container,
.activity-time-selector .passenger-selector-container .dropoff-container {
  border-top: 1px solid #DDD;
  margin-top: 10px;
  padding-top: 10px;
}
.activity-time-selector .on-request-msg {
  margin-top: 8px;
}
.activity-time-selector label {
  font-size: 13px;
  vertical-align:center;
}
.activity-time-selector .extras-container .extras-pricingcategory-selectors {
  float: left;
  margin-right: 15px;
  margin-bottom: 10px;
  margin-top: 5px;
}
.activity-time-selector .extras-container .extras-pricingcategory-selectors select,
.activity-time-selector .extras-container .extras-pricingcategory-selectors input {
  font-weight: normal;
}
.activity-time-selector .extras-container .extra-unit-selector {
  font-size: 13px;
  margin-left: 18px;
}
.activity-time-selector .extras-container .included-extras h5 {
  margin-top: 15px;
  font-weight: bold;
  margin-bottom: 3px;
}
.activity-time-selector .extras-container .included-extras ul {
  margin-bottom: 0px;
}
.activity-time-selector .extras-container .included-extras li {
  font-size: 13px;
}
.activity-time-selector .total {
  text-align: right;
  font-size: 16px;
  margin-top: 10px;
}
.activity-time-selector .total .total-amount {
  display: inline-block;
  margin-left: 10px;
  font-weight: bold;
  font-size: 18px;
}
.activity-time-selector .total .total-amount .amount {
  font-size: 24px;
  font-weight: bold;
  margin-top: 5px;
}
.activity-time-selector .button-container {
  margin-top: 10px;
  text-align: right;
}
@media (min-width: 481px) {
  .activity-time-selector .passenger-selector-container .passenger-selector-separator {
    display: none;
  }
}
@media (max-width: 480px) {
  .activity-time-selector .passenger-selector-container .passenger-selector-separator {
    display: '';
  }
}
.availability-calendar .calendar-header {
  font-size: 20px;
  text-align: center;
  margin: 3px 0 15px;
  position: relative;
}
.availability-calendar .calendar-header .prev,
.availability-calendar .calendar-header .next {
  position: absolute;
  top: 0px;
}
.availability-calendar .calendar-header .prev {
  left: 0px;
}
.availability-calendar .calendar-header .next {
  right: 0px;
}
.availability-calendar .calendar-header h4 {
  font-size: 16px;
  margin: 0px;
}
.availability-calendar .calendar-header h4 a {
  display: inline-block;
  margin: 6px;
}
.availability-calendar .calendar-header .month-selector select {
  margin: 0px;
  margin-bottom: 2px;
}
.availability-calendar .calendar {
  width: 99%;
}
.availability-calendar .calendar th {
  text-align: center;
}
.availability-calendar .legend {
  padding-top: 4px;
  text-align: right;
  font-size: 0.9em;
}
.availability-calendar .legend .available,
.availability-calendar .legend .sold-out {
  position: relative;
  top: 2px;
  display: inline-block;
  width: 10px;
  height: 10px;
  border: 1px solid #AAA;
}
.availability-calendar .legend .available {
  background-color: #DFF0D8;
}
.availability-calendar .legend .sold-out {
  background-color: #F2DEDE;
}
.availability-calendar td.daycell {
  text-align: right;
  background-color: white;
  width: 14.25%;
  min-width: 30px;
  border: 1px solid #CCC;
}
.availability-calendar td.daycell .date {
  padding-right: 4px;
  padding-top: 6px;
  padding-bottom: 6px;
  border: 2px solid #FFF;
}
.availability-calendar td.daycell.not-in-month {
  color: #AAA;
  background-color: #EEE;
}
.availability-calendar td.daycell.not-in-month .date {
  border: 2px solid #EEE;
}
.availability-calendar td.daycell.today {
  font-weight: bold;
}
.availability-calendar td.daycell.available {
  color: #468847;
  background-color: #DFF0D8;
  cursor: pointer;
}
.availability-calendar td.daycell.available .date {
  border: 2px solid #DFF0D8;
}
.availability-calendar td.daycell.available.selected {
  color: #000;
}
.availability-calendar td.daycell.available.selected .date {
  border: 2px solid green;
  background-color: green;
  color: white;
}
.availability-calendar td.daycell.sold-out {
  color: #B94A48;
  background-color: #F2DEDE;
}
.availability-calendar td.daycell.sold-out .date {
  border: 2px solid #F2DEDE;
}
.availability-calendar td.daycell.sold-out.selected .date {
  border: 2px solid #000;
}
.dropoff-container
{
	 display:none;	
}
.participant-selector
{
	font-weight:bold;
}
.pickup-container
{
    font-weight: bold;
}
.opening-hours{
    display: none;
}
@media (min-width: 768px) {
.daycell:hover, .daycell.past:hover, .daycell.soldout:hover, .daycell.not-in-month:hover {
	cursor:not-allowed;
}
.daycell.available:hover {
    background: #fff !important;
	cursor:pointer;
}
}