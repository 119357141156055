html, body {
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
}
body {
    background-color: transparent;
    color: rgb(52, 64, 78);
    font-style: normal;
    font-variant-ligatures: normal;
    font-variant-caps: normal;
    font-variant-numeric: normal;
    font-variant-east-asian: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: normal;
    touch-action: manipulation;
    margin: 0px;
    overflow: auto !important;
}
#dataTables-example{
    font-size: 14px;
}
footer {
    font-size: 14px;
}
div {
    line-height: 1.5;
}
h1 {
    display: block;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}
h2 {
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    margin-bottom: 8px;
}
h3 {
    display: block;
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    margin-bottom: 8px;
}
/* -------------------------------------------------------------------- */
.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 2px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none !important;
  outline: none;
  box-shadow: none;
}
.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}


/* menu hover */
.menu-hover {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
}
.menu-hover:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 51%;
  right: 51%;
  bottom: 0;
  background: #c53c46;
  height: 4px;
  -webkit-transition-property: left, right;
  transition-property: left, right;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.menu-hover:hover:before, .menu-hover:focus:before, .menu-hover:active:before {
  left: 0;
  right: 0;
}

/* -------------------------------------------------------------------- */

.calendar
{
  font-size:13px;
}

.rates-container
{
  margin-bottom:10px; 
}

.start-times-container
{
  margin-bottom:10px; 
}

.time
{
  margin-left:10px;
  
}

.label {
  display: inline;
  padding: .2em .6em .3em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;
}

a.label:hover,
a.label:focus {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

.label:empty {
  display: none;
}

.btn .label {
  position: relative;
  top: -1px;
}

.label-default {
  background-color: #777;
}

.label-default[href]:hover,
.label-default[href]:focus {
  background-color: #5e5e5e;
}

.label-primary {
  background-color: #337ab7;
}

.label-primary[href]:hover,
.label-primary[href]:focus {
  background-color: #286090;
}

.label-success {
  background-color: #5cb85c;
}

.label-success[href]:hover,
.label-success[href]:focus {
  background-color: #449d44;
}

.label-info {
  background-color: #5bc0de;
}

.label-info[href]:hover,
.label-info[href]:focus {
  background-color: #31b0d5;
}

.label-warning {
  background-color: #f0ad4e;
}

.label-warning[href]:hover,
.label-warning[href]:focus {
  background-color: #ec971f;
}

.label-danger {
  background-color: #d9534f;
}

.label-danger[href]:hover,
.label-danger[href]:focus {
  background-color: #c9302c;
}

/* ----------------------------------------------------- */
.img-circle {
    border-radius: 50%;
}

.intro-header {
  background-color: #000000;
  background: no-repeat center;
  background-attachment: scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
}

.intro-header .site-heading {
  padding: 100px 0 30px;
  color: white;
  max-width:700px;
  margin:0 auto;
}

@media only screen and (min-width: 768px) {
  .intro-header .site-heading {
    padding: 150px 0;
  }
  .intro-header .site-heading h1{
    font-size: 80px;
  }
}

.intro-header .site-heading {
  text-align: center;
}

.intro-header .site-heading h1 {
  font-size: 50px;
  text-shadow: 2px 2px 3px rgba(0,0,0,0.6);
  filter: alpha(opacity=60);
  
}

.transbox {
    margin: 0px;
	border-radius: 10px;
	color: #ffffff;
    background-color: #000000;
    border: 0px;
    background: rgba(0,0,0,0.4);
}

.tldate {
 	 display: block;
 	 width: 150px;
 	 background: #e9f0f5;
 	 color: #becad2;
 	 margin: 0 auto;
 	 padding: 3px 0;
 	 text-align: center;
 	 font-size: 1.1em;
}

.text-faded {
 	 color: rgba(255, 255, 255, 0.7);
 	 font-size:16px;
}

blockquote {
  font-style: italic;
  color: #868e96;
}

.section-heading {
  font-size: 22px;
  font-weight: 700;
  margin-top: 60px;
}

.section-subheading {
  font-size: 16px;
  font-weight: 700;
}

.caption {
  font-size: 14px;
  font-style: italic;
  display: block;
  margin: 0;
  padding: 10px;
  text-align: center;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

article{
	background-color:#FFFFFF;
	padding-top:50px;
	padding-bottom:50px;
	font-size:16px;
}

.style-4 del {
  color: rgba(169, 169, 169, 0.5);
  text-decoration: none;
  position: relative;
  font-size: 30px;
  font-weight: 100;
}

.style-4 del:before {
  content: " ";
  display: block;
  width: 100%;
  border-top: 3px solid rgba(169, 169, 169, 0.8);
  height: 4px;
  position: absolute;
  bottom: 20px;
  left: 0;
  transform: rotate(-11deg);
}

.style-4 del:after {
  content: " ";
  display: block;
  width: 100%;
  border-top: 3px solid rgba(169, 169, 169, 0.8);
  height: 4px;
  position: absolute;
  bottom: 20px;
  left: 0;
  transform: rotate(11deg);
}

.style-4 ins {
  font-size: 30px;
  font-weight: 800;
  text-decoration: none;
  padding: 1em .11em 1em .5em;
}

/* -------------------------------------------------------------------- */

#title
{
	font-size:1.4em;
}
.team-member {
  margin-bottom: 50px;
  text-align: center;
}
.team-member img {
  width: 200px;
  height: 200px;
  border: 5px solid rgba(0, 0, 0, 0.1);
  
}
.team-member h4 {
  margin-top: 20px;
  text-transform: none;
}
.team-member p {
  margin-top: 0;
}

@media(min-width:768px) {
	#title
	{
		font-size:1.8em;
	}

    
}

/* -------------------------------------------------------------------- */

.map-container{
	overflow:hidden;
	padding-bottom:56.25%;
	position:relative;
	height:0;
}
.map-container iframe{
	left:0;
	top:0;
	height:100%;
	width:100%;
	position:absolute;
}
.map-responsive{
    overflow:hidden;
    padding-bottom:56.25%;
    position:relative;
    height:0;
}
.map-responsive iframe{
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
}

/* -------------------------------------------------------------------- */

.timeline {
    position: relative;
    padding: 20px 0 20px;
    list-style: none;
}
.timeline-heading {
	font-size:14px;
	}
.timeline-footer {
	font-size:14px;
	margin-top:16px;
	}
.timeline:before {
    content: " ";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 3px;
    margin-left: -1.5px;
    background-color: #d7e4ed;
}
.timeline > li {
    position: relative;
    margin-bottom: 20px;
}
.timeline > li:before,
.timeline > li:after {
    content: " ";
    display: table;
}
.timeline > li:after {
    clear: both;
}
.timeline > li:before,
.timeline > li:after {
    content: " ";
    display: table;
}
.timeline > li:after {
    clear: both;
}
.timeline > li > .timeline-panel {
    float: left;
    position: relative;
    width: 45%;
    padding: 20px;
    border: 1px solid #d4d4d4;
    border-radius: 10px;
    -webkit-box-shadow: 0 1px 6px rgba(0,0,0,0.175);
    box-shadow: 0 1px 6px rgba(0,0,0,0.175);
}
.timeline > li > .timeline-panel:before {
    content: " ";
    display: inline-block;
    position: absolute;
    top: 26px;
    right: -15px;
    border-top: 15px solid transparent;
    border-right: 0 solid #ccc;
    border-bottom: 15px solid transparent;
    border-left: 15px solid #ccc;
}
.timeline > li > .timeline-panel:after {
    content: " ";
    display: inline-block;
    position: absolute;
    top: 27px;
    right: -14px;
    border-top: 14px solid transparent;
    border-right: 0 solid #fff;
    border-bottom: 14px solid transparent;
    border-left: 14px solid #fff;
}
.timeline > li > .timeline-badge {
    width: 50px;
    height: 50px;
    font-size: 1.2em;
    text-align: center;
    position: absolute;
    top: 20px;
    left: 50%;
    margin-left: -25px;
    z-index: 100;
	border: 2px solid #ffffff;
    border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;
}
.timeline > li.timeline-inverted > .timeline-panel {
    float: right;
}
.timeline > li.timeline-inverted > .timeline-panel:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;
}
.timeline > li.timeline-inverted > .timeline-panel:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
}

.timeline > li > .timeline-badge > span.timeline-day {
    font-size: 1.2em;
	color:#FFFFFF;
}
.timeline > li > .timeline-badge > span.timeline-month {
    font-size: .6em;
    position: relative;
    top: -17px;
	color:#FFFFFF;
}

.timeline-badge.primary {
    background-color: #2e6da4 !important;
}

.timeline-badge.success {
    background-color: #75ce66 !important;
}

.timeline-badge.warning {
    background-color: #f0ad4e !important;
}

.timeline-badge.danger {
    background-color: #d9534f !important;
}

.timeline-badge.info {
    background-color: #5bc0de !important;
}

.timeline-title {
    margin-top: 0;
    color: inherit;
}
.timeline-body > p,
.timeline-body > ul {
    margin-bottom: 0;
}

.timeline-body > p + p {
    margin-top: 5px;
}
@media(max-width:767px) {
    ul.timeline:before {
        left: 40px;
    }

    ul.timeline > li > .timeline-panel {
        width: calc(100% - 90px);
        width: -moz-calc(100% - 90px);
        width: -webkit-calc(100% - 90px);
    }

    ul.timeline > li > .timeline-badge {
        top: 16px;
        left: 15px;
        margin-left: 0;
    }

    ul.timeline > li > .timeline-panel {
        float: right;
    }

    ul.timeline > li > .timeline-panel:before {
        right: auto;
        left: -15px;
        border-right-width: 15px;
        border-left-width: 0;
    }

    ul.timeline > li > .timeline-panel:after {
        right: auto;
        left: -14px;
        border-right-width: 14px;
        border-left-width: 0;
    }
}

/* -------------------------------------------------------------------- */

.bd-callout {
    padding: 1.25rem;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    border: 1px solid #eee;
    border-left-width: .25rem;
    border-radius: .25rem
}
.bd-callout h4 {
    margin-top: 0;
    margin-bottom: .25rem
}
.bd-callout p:last-child {
    margin-bottom: 0
}
.bd-callout code {
    border-radius: .25rem
}
.bd-callout+.bd-callout {
    margin-top: -.25rem
}
.bd-callout-info {
    border-left-color: #5bc0de
}
.bd-callout-theme {
    border-left-color: #1D57C7
}
.bd-callout-warning {
    border-left-color: #f0ad4e
}
.bd-callout-danger {
    border-left-color: #d9534f
}

/* -------------------------------------------------------------------- */

 .btn.btn-social-icon {
     width: 38px;
     height: 38px;
     padding: 4px 0
 }
 .btn-facebook {
     background: #3b579d;
     color: #ffffff
 }
 .btn-tripadvisor {
     background: #00af87;
     color: #ffffff
 }
 .btn-airbnb {
     background: #fd5c63;
     color: #ffffff
 }
 .btn-instagram {
     background: #e1306c;
     color: #ffffff
 }

/* -------------------------------------------------------------------- */

.required:after {
  content:" *";
  color: red;
}

/* -------------------------------------------------------------------- */


.loader {
  font-size: 10px;
  margin: 50px auto;
  text-indent: -9999em;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  background: #0433ff;
  background: -moz-linear-gradient(left, #1D57C7 10%, rgba(4,51,255, 0) 42%);
  background: -webkit-linear-gradient(left, #1D57C7 10%, rgba(4,51,255, 0) 42%);
  background: -o-linear-gradient(left, #1D57C7 10%, rgba(4,51,255, 0) 42%);
  background: -ms-linear-gradient(left, #1D57C7 10%, rgba(4,51,255, 0) 42%);
  background: linear-gradient(to right, #1D57C7 10%, rgba(4,51,255, 0) 42%);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.loader:before {
  width: 50%;
  height: 50%;
  background: #1D57C7;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}

.loader:after {
  background: #ffffff;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* -------------------------------------------------------------------- */

@media screen and (max-width: 767px) {
  div.dataTables_wrapper div.dataTables_paginate ul.pagination {
    text-align: center;
	justify-content:center;
  font-size: 12px;
  }
}

@media screen and (max-width: 767px) {
	div.dataTables_wrapper div.dataTables_paginate {
		float:none;
		text-align:center;
    font-size: 12px;
		}
	
	li.paginate_button.previous {
        display: inline;
    }
 
    li.paginate_button.next {
        display: inline;
    }
 
    li.paginate_button {
        display: none;
    }
}

/* -------------------------------------------------------------------- */

/* -------------------------------------------------------------------- */


/* -------------------------------------------------------------------- */


.btn-primary{
    background-color: #1D57C7;
    border-color:#1D57C7;
  color:#FFFFFF;
  
  display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    overflow: hidden;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: color, background-color, border-color;
    transition-property: color, background-color, border-color;
}

.btn-primary:hover{
    background-color: #2870fd;
    border-color:#2870fd;
  color:#FFFFFF;
}

.btn-primary:focus{
    background-color:#2870fd;
    border-color:#2870fd;
  color:#FFFFFF;
}
.btn-primary:active{
    background-color:#2870fd;
    border-color:#2870fd;
  color:#FFFFFF;
}
.btn-primary:not(:disabled):not(.disabled):active{
    color: #FFFFFF;
    background: #1D57C7;
    border-color: #1D57C7;
    outline: 0 none;
}
.btn-primary:not(:disabled):not(.disabled):focus{
    box-shadow: 0 0 5px #1D57C7;
}
.btn-primary[disabled] {
    background: #1D57C7 !important;
    border-color:#1D57C7 !important;
}
.btn-primary[disabled]:hover {
    background: #1D57C7 !important;
    border-color:#1D57C7 !important;
}

/* -------------------------------------------------------------------- */

.btn-theme{
    background-color: #1D57C7;
    border-color:#1D57C7;
	color:#FFFFFF;
	
	display: inline-block;
  	vertical-align: middle;
  	-webkit-transform: perspective(1px) translateZ(0);
  	transform: perspective(1px) translateZ(0);
  	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  	overflow: hidden;
  	-webkit-transition-duration: 0.3s;
  	transition-duration: 0.3s;
  	-webkit-transition-property: color, background-color, border-color;
  	transition-property: color, background-color, border-color;
}

.btn-theme:hover{
    background-color: #2870fd;
    border-color:#2870fd;
	color:#FFFFFF;
}

.btn-theme:focus{
    background-color:#2870fd;
    border-color:#2870fd;
	color:#FFFFFF;
}
.btn-theme:active{
    background-color:#2870fd;
    border-color:#2870fd;
	color:#FFFFFF;
}
.btn-theme:not(:disabled):not(.disabled):active{
    color: #FFFFFF;
    background: #1D57C7;
    border-color: #1D57C7;
    outline: 0 none;
}
.btn-theme:not(:disabled):not(.disabled):focus{
    box-shadow: 0 0 5px #1D57C7;
}
.btn-theme[disabled] {
    background: #1D57C7 !important;
    border-color:#1D57C7 !important;
}
.btn-theme[disabled]:hover {
    background: #1D57C7 !important;
    border-color:#1D57C7 !important;
}

.text-theme
{
    color:#1D57C7;
}
.badge-theme
{
    background-color:#1D57C7;
    color:#FFFFFF;
}
.badge-theme:hover
{
    background-color:#FFFFFF;
}
.hr-theme
{
    max-width:50px;
    border-color:#1D57C7;
    border-width:3px;
}

/* -------------------------------------------------------------------- */

.container-book {
  position: relative;
}

.image-book {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: .7s ease;
  backface-visibility: hidden;
}

.middle-book {
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transition: .7s ease;
  text-align: center;
}

.container-book:hover .image-book {
  opacity: 0.6;
}

.container-book:hover .middle-book {
  opacity: 1;
}

.text-book {
  background-color: #4CAF50;
  color: white;
  font-size: 16px;
  padding: 16px 32px;
}

.product-detail
{
  font-size:13px;
  margin-left:-8px
}
.button-book-mobile
  {
    display: none;
  }
.display-on-desktop
{
    display: block;
}

.footer {
    text-align: center;
}

@media(max-width:767px) {
  .product-detail
  {
    font-size:11px;
    margin-left:-8px
  }
  .button-book-mobile
  {
    display: block;
  }
  .display-on-desktop
    {
    display: none;
    }
  .footer {
        text-align: left;
    }
}

.iti {
  width: 100%;
  display: block;
}